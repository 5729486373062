
//TODO: clean up this mess of a package
// 1. Split out most of the search functionality into separate services
//    a. Sort + Query should be together
//    b. The query builder should be a separate component (and should support async actions)
//        i. It should provide the input bar, and needs access to the job, because it may add cases/controls
//        ii. It should expose a function to add cases/controls through a separate service, which will be re-used
//            for the toolbar button trigger of that (the default trigger will be typing cases and not having cases entered)

/*@ngInject*/ function HintsController($log, $window, $location, $http, $q,
  $routeParams, $anchorScroll, $mdDialog, _, SETTINGS, userProfile, store) {
  const storeName = 'searchHints';
  this.hasHint = false;
  this.firstTime = false;

  // Not async; requires profile to be loaded
  const _isFirstTime = () => {
    return (userProfile.hasUserProfile && !userProfile.user.hints.search) || !store.get(storeName);
  };

  this.$onChanges = () => {
    if (!this.query) {
      this.hasHint = false;
      return;
    }

    if (this.query.indexOf('<') > -1) {
      this.onHint();
      this.hasHint = true;

      this.firstTime = _isFirstTime();

      if (this.firstTime) {
        this.drawDialog();
        this.storeSeen();
      }
    } else {
      this.hasHint = false;
      this.firstTime = false;
    }
  };


  this.drawDialog = ($event) => {
    const originatingElement = angular.element(document.getElementById('search-hints'));

    return $mdDialog.show({
      controller: ($scope, $mdDialog) => {
        $scope.ok = () => {
          $mdDialog.cancel();
        };
      },
      templateUrl: 'jobs/results/search/hints/jobs.results.search.hints.tpl.html',
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      hasBackdrop: false,
      targetEvent: $event,
      disableParentScroll: false,
      openFrom: originatingElement,
      closeTo: originatingElement
    }).finally(() => {
      this.storeSeen();
    });
  };


  this.storeSeen = () => {
    if (userProfile.hasUserProfile) {
      if (!userProfile.user.hints.search.missing) {
        _updateProfile(user, { hints: { search: { missing: true } } });
      }
    }

    store.set(storeName, { missing: true });
  };

  const _updateProfile = (user, updateData) => {
    this.updating = true;

    userProfile.update(updateData).then((updatedUser) => {
      // This isn't strictly necessary; jobs.events.service should pick this up
      // It is more of a precaution against socket.io failure
      // In any case jobTracker additions are idempotent, so no big deal
      // [this.err, this.job] = jobTracker.add(updatedJob);

      this.updateSuccess = true;
      // this.onUpdated();
    }, (err) => {
      this.updateErr = err;
    }).finally(() => {
      this.updating = false;

      // Don't clear errors
      // TODO: handle clearing errors on click
    });
  };
}

angular.module('sq.jobs.results.search.hints.component',
  ['sq.user.profile.service', 'angular-storage'])

  .component('sqSearchHints', {
    bindings: {
      query: '<',
      onHint: '&',
      showHint: '<',
    }, // isolate scope
    template: `
                <md-icon ng-show='$ctrl.hasHint' ng-click='$ctrl.drawDialog()'  class='material-icons' style='font-size: 16px;
                height: 16px;
                width: 16px;
                position: absolute;
                left: -9px;
                top: 40px; cursor:pointer; cursor:hand'>info</md-icon>
              `,
    controller: HintsController,
  });